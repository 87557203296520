















import { Component, Vue } from "vue-property-decorator";
import {
  createEvent,
  observable,
  observer,
  registerWinFun,
} from "@/utils/common.util";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { WechatApi } from "@/api/wechat.api";
import { ActionSheet, Lazyload } from "vant";
import InteractionLib from "@/utils/interaction.lib";
import { OPENID_NAME, SHARE_TEXT, TOKEN_NAME } from "@/constant";

Vue.use(Lazyload);
// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});
@Component({
  components: {
    ActionSheet,
  },
})
export default class App extends Vue {
  interval = 0;
  globalState = getModule(GlobalStateStore);
  actions = [{ name: "立即登录", id: 1 }];
  wechat = InteractionLib.mobile.wechat;
  flutter = false;

  get routerKey() {
    return this.$route.path + this.$route.query?.t;
  }

  get isRouter() {
    if (this.wechat) return true;
    if (InteractionLib.isFlutter()) return this.flutter;
    return true;
  }

  get routerName(): string {
    return this.$route.name || "";
  }

  async onSelect({ id }: Record<string, number>): Promise<void> {
    if (id !== 1) return;
    if (!this.wechat) {
      InteractionLib.login();
      return;
    }
    location.href = await WechatApi.getAuthUri();
  }

  get loginTip(): boolean {
    // TODO: VGN-7009
    const pathName = [
      "BlindBoxActivityPrivateId",
      "timitwelfarepop",
      "timitwelfarepage",
      "timitwelfarepopsmall",
      "wishlist",
    ];
    if (InteractionLib.isApp()) {
      pathName.push("Store");
      pathName.push("Welfare");
      pathName.push("specialsale");
      pathName.push("StoreDetail");
    }
    if (pathName.includes(this.routerName)) return false;
    return this.globalState.loginTip;
  }

  set loginTip(status: boolean) {
    this.globalState.setLoginTip(status);
  }

  onCancel(): void {
    this.loginTip = false;
  }

  async setUserInfo({ data }: { data: string }) {
    let info;
    try {
      info = JSON.parse(data);
      this.globalState.setUserToken(info?.token || null);
      // 设置app-openid
      this.globalState.setOpenId(info?.openId || info?.token || null);
      observable("refreshInit", "");
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  setTransparence({ data }: { data: string }) {
    if (data == "0") {
      return this.globalState.setTransparence(0.5);
    }
    this.globalState.setTransparence(1);
  }

  shareSuccess({ data }: { data: string }) {
    parseInt(data);
  }

  setSystemInfo({ data }: { data: number }) {
    try {
      this.globalState.setSystemInfo(data);
    } catch (e) {
      throw new Error("非法来源");
    }
  }

  setVersionCode({ data }: { data: string }) {
    try {
      this.globalState.setVersionCode(data);
      this.globalState.getConfig();
    } catch (e) {
      throw new Error("非法来源");
    }
  }

  setPackageName({ data }: { data: string }) {
    try {
      this.globalState.setPackageName(data);
    } catch (e) {
      throw new Error("非法来源");
    }
  }

  async mounted(): Promise<void> {
    const isFlutter = await InteractionLib.flutterInAppWebViewPlatformReady();
    observer("shareSuccess", (e: any) => this.shareSuccess(e));
    observer("setUserInfo", (e: any) => this.setUserInfo(e));
    observer("setSystemInfo", (e: any) => this.setSystemInfo(e));
    observer("setVersionCode", (e: any) => this.setVersionCode(e));
    observer("setPackageName", (e: any) => this.setPackageName(e));
    observer("setTransparence", (e: any) => this.setTransparence(e));
    const token = localStorage.getItem(TOKEN_NAME);
    const open_id = localStorage.getItem(OPENID_NAME);
    if (!isFlutter && token && open_id) {
      this.globalState.setUserToken(token);
      this.globalState.setOpenId(open_id);
      this.globalState.getUserInfo();
    }
    registerWinFun("updateGoldBlindBoxStatus", (i: any) =>
      createEvent("updateGoldBlindBoxStatus", i)
    );
    registerWinFun("shareSuccess", (i: any) => createEvent("shareSuccess", i));

    registerWinFun("setUserInfo", (i: any) => createEvent("setUserInfo", i));
    registerWinFun("setSystemInfo", (i: any) =>
      createEvent("setSystemInfo", i)
    );
    registerWinFun("payResultWx", (i: any) => createEvent("payResultWx", i));
    registerWinFun("setVersionCode", (i: any) =>
      createEvent("setVersionCode", i)
    );
    registerWinFun("payResult", (i: any) => createEvent("payResult", i));
    registerWinFun("setPackageName", (i: any) =>
      createEvent("setPackageName", i)
    );
    registerWinFun("setUserToken", (token: string) => {
      this.globalState.setUserToken(token);
      if (isFlutter) this.globalState.setOpenId(token);
      this.globalState.getUserInfo();
    });
    registerWinFun("setOpenId", (openId: string) => {
      this.globalState.setOpenId(openId);
    });
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      observable("countdownNotice", "");
    }, 1000);
    if (!this.wechat) {
      InteractionLib.getVersionCode();
      InteractionLib.getPackageName();
      InteractionLib.hideWebMenu();
      InteractionLib.getUserInfo();
      InteractionLib.getSystemInfo();
      InteractionLib.getHeader().then((res) => {
        this.globalState.setHeaderData(res);
      });
    }
    this.flutter = isFlutter;
    if (!isFlutter) this.handleWechatPageConfig();
  }

  async handleWechatPageConfig(): Promise<void> {
    const sign = await WechatApi.getAuthSign();
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      ...sign,
      jsApiList: [
        "updateAppMessageShareData",
        "updateTimelineShareData",
        "chooseImage",
        "previewImage",
        "uploadImage",
        "downloadImage",
        "chooseWXPay",
        "closeWindow",
        "getNetworkType",
        "hideMenuItems",
        "showMenuItems",
      ], // 必填，需要使用的JS接口列表
      openTagList: ["wx-open-subscribe"],
    });
    wx.ready(() => {
      wx.updateAppMessageShareData({
        title: "超级蒸汽商城", // 分享标题
        desc: SHARE_TEXT, // 分享描述
        link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "https://cdn.vgn.cn/static/logo-new1.png", // 分享图标
      });
      wx.updateTimelineShareData({
        title: "超级蒸汽商城", // 分享标题
        desc: SHARE_TEXT, // 分享描述
        link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: "https://cdn.vgn.cn/static/logo-new1.png", // 分享图标
      });
      wx.hideMenuItems({
        menuList: [
          "menuItem:originPage",
          "menuItem:copyUrl",
          "menuItem:openWithQQBrowser",
          "menuItem:openWithSafari",
          "menuItem:share:email",
          "menuItem:share:brand",
          "menuItem:delete",
          "menuItem:share:QZone",
          "menuItem:share:facebook",
          "menuItem:share:weiboApp",
          "menuItem:share:qq",
        ], // 要显示的菜单项，所有menu项见附录3
      });
      if (this.$route.name === "wishlist") {
        wx.showMenuItems({
          menuList: [
            "menuItem:originPage",
            "menuItem:copyUrl",
            "menuItem:openWithQQBrowser",
            "menuItem:openWithSafari",
          ],
        });
      }
    });
  }

  destroyed(): void {
    clearInterval(this.interval);
    // window.removeEventListener("scroll", this.handleScroll.bind(this));
  }
}
