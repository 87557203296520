import { RouteConfig } from "vue-router";

export const BaseRouter: Array<RouteConfig> = [
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("@/views/mine/AboutUs.vue"),
    meta: {
      title: "关于我们",
      desc: "关于我们",
      class: "__theme_white",
    },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("@/views/mine/ContactUs.vue"),
    meta: {
      title: "联系我们",
      desc: "联系我们",
      class: "__theme_white",
    },
  },
  {
    path: "/my-discount",
    name: "MyDiscount",
    component: () => import("@/views/mine/MyDiscount.vue"),
    meta: {
      title: "我的优惠",
      desc: "我的优惠",
      class: "__theme_white",
    },
  },
  {
    path: "/my-supply",
    name: "MySupply",
    component: () => import("@/views/mine/MySupply.vue"),
    meta: {
      title: "我的补给/卡券",
      desc: "我的补给/卡券",
      class: "__theme_white",
    },
  },
  {
    path: "/my-voucher",
    name: "MyVoucher",
    component: () => import("@/views/mine/MyVoucher.vue"),
    meta: {
      title: "我的卡券",
      desc: "我的卡券",
      class: "__theme_white",
    },
  },
  {
    path: "/my-voucher/:id",
    name: "MyVoucherDetail",
    component: () => import("@/views/mine/MyVoucherDetail.vue"),
    meta: {
      title: "卡券详情",
      desc: "卡券详情",
      class: "__theme_white",
    },
  },
  {
    path: "/my-voucher/:id/entity",
    name: "MyVoucherEntityDetail",
    component: () => import("@/views/mine/MyVoucherEntityDetail.vue"),
    meta: {
      title: "补给详情",
      desc: "补给详情",
      class: "__theme_white",
    },
  },
  {
    path: "/my-delivery-record",
    name: "MyDeliveryRecord",
    component: () => import("@/views/mine/MyDeliveryRecord.vue"),
    meta: {
      title: "发货记录",
      desc: "发货记录",
      class: "__theme_white",
    },
  },
  {
    path: "/my-delivery-record/:id",
    name: "MyDeliveryRecordDetail",
    component: () => import("@/views/mine/MyDeliveryRecordDetail.vue"),
    meta: {
      title: "物流详情",
      desc: "物流详情",
      class: "__theme_white",
    },
  },
  {
    path: "/apply-delivery",
    name: "ApplyDelivery",
    component: () => import("@/views/mine/ApplyDelivery.vue"),
    meta: {
      title: "申请发货",
      desc: "申请发货",
      class: "__theme_white",
    },
  },
  {
    path: "/my-order",
    name: "MyOrder",
    component: () => import("@/views/mine/MyOrder.vue"),
    meta: {
      title: "我的订单",
      desc: "我的订单",
      class: "__theme_white",
    },
  },
  {
    path: "/my-order/:order_no",
    name: "MyOrderDetail",
    component: () => import("@/views/mine/MyOrderDetail.vue"),
    meta: {
      title: "订单详情",
      desc: "订单详情",
      class: "__theme_white",
    },
  },
  {
    path: "/special/sales",
    name: "SpecialSales",
    component: () => import("@/views/special-sales/SpecialSales.vue"),
    meta: {
      title: "限时特卖",
      desc: "限时特卖",
      class: "__theme_white",
    },
  },
  {
    path: "/sp-store/:id",
    name: "StoreDetail",
    component: () => import("@/views/store/StoreDetailV3.vue"),
    meta: {
      title: "商品详情",
      desc: "商品详情",
      class: "__theme_white",
    },
  },
  {
    path: "/store/:id/order",
    name: "StoreOrder",
    component: () => import("@/views/store/StoreOrder.vue"),
    meta: {
      title: "确认订单",
      desc: "商品确认订单",
      class: "__theme_white",
    },
  },
  {
    path: "/store/:id/result",
    name: "StoreResult",
    component: () => import("@/views/store/StoreResult.vue"),
    meta: {
      title: "商品支付结果",
      desc: "商品支付结果",
      class: "__theme_white",
    },
  },
  {
    path: "/blind-box/:id",
    name: "BlindBoxDetail",
    component: () => import("@/views/destiny-draw/index.vue"),
    meta: {
      title: "超值补给箱",
      desc: "盲盒商品详情",
      class: "__theme_dark",
    },
  },
  {
    path: "/blind-box/:id/dragon",
    name: "BlindBoxDetailDragon",
    component: () => import("@/views/destiny-draw/index-dragon.vue"),
    meta: {
      title: "超值补给箱",
      desc: "盲盒商品详情",
    },
  },
  {
    path: "/synthetic",
    name: "Synthetic",
    component: () => import("@/views/synthetic-v3/index.vue"),
    meta: {
      title: "合成升级",
      desc: "合成升级",
      class: "__theme_white",
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search/index.vue"),
    meta: {
      title: "搜索",
      desc: "搜索",
      class: "__theme_white",
    },
  },
  {
    path: "/mysupply",
    name: "mysupply",
    component: () => import("@/views/supply/MySupply.vue"),
    meta: {
      title: "我的补给",
      desc: "我的补给",
      class: "__theme_white",
    },
  },
  {
    path: "/wallet",
    name: "wallet",
    component: () => import("@/views/wallet/index.vue"),
    meta: {
      title: "蒸汽钱包",
      desc: "蒸汽钱包",
      class: "__theme_white",
    },
  },

  {
    path: "/demand",
    name: "demand",
    component: () => import("@/views/demand/index.vue"),
    meta: {
      title: "我的求购",
      desc: "我的求购",
      class: "__theme_white",
    },
  },
  {
    path: "/market",
    name: "market",
    component: () => import("@/views/market/index.vue"),
    meta: {
      title: "求购商品",
      desc: "求购商品",
      class: "__theme_white",
    },
  },
  {
    path: "/marketpay/:id",
    name: "marketpay",
    component: () => import("@/views/market-pay/index.vue"),
    meta: {
      title: "求购商品",
      desc: "求购商品",
      class: "__theme_white",
    },
  },
  {
    path: "/sell",
    name: "sell",
    component: () => import("@/views/sell/index.vue"),
    meta: {
      title: "集市挂售",
      desc: "集市挂售",
      class: "__theme_white",
    },
  },
  {
    path: "/hangsell",
    name: "hangsell",
    component: () => import("@/views/hang-sell/index.vue"),
    meta: {
      title: "我要挂售",
      desc: "我要挂售",
      class: "__theme_white",
    },
  },
  {
    path: "/moneydetail",
    name: "moneydetail",
    component: () => import("@/views/money-detail/MoneyDetail.vue"),
    meta: {
      title: "资金流水",
      desc: "资金流水",
      class: "__theme_white",
    },
  },
  {
    path: "/common-problem",
    name: "commonproblem",
    component: () => import("@/views/common-problem/CommonProblem.vue"),
    meta: {
      title: "常见问题",
      desc: "常见问题",
      class: "__theme_white",
    },
  },
  {
    path: "/weplay-game/:id",
    name: "weplaygame",
    component: () => import("@/views/weplay-game/WeplayGame.vue"),
    meta: {
      title: "WEPLAY x 游戏动力",
      desc: "WEPLAY x 游戏动力",
    },
  },
  {
    path: "/special-sale/:id/member",
    name: "specialsale",
    component: () => import("@/views/SpecialSale/SpecialSalMember.vue"),
    meta: {
      title: "专题特卖",
      desc: "专题特卖",
    },
  },
  {
    path: "/optional",
    name: "optional",
    component: () => import("@/views/optional/Optional.vue"),
    meta: {
      title: "请选择目标补给",
      desc: "请选择目标补给",
      class: "__theme_white",
    },
  },
  {
    path: "/prize-detail",
    name: "prizedetail",
    component: () => import("@/views/optional/PrizeDetail.vue"),
    meta: {
      title: "合成自选",
      desc: "合成自选",
    },
  },
  {
    path: "/special-sale/:id",
    name: "specialsale",
    component: () => import("@/views/SpecialSale/SpecialSale.vue"),
    meta: {
      title: "专题特卖",
      desc: "专题特卖",
      class: "__theme_white",
    },
  },
  {
    path: "/supply-detail",
    name: "supplydetal",
    component: () => import("@/views/supply-detail/index.vue"),
    meta: {
      title: "补给明细",
      desc: "补给明细",
      class: "__theme_white",
    },
  },
  {
    path: "/weplay-media",
    name: "weplaymedia",
    component: () => import("@/views/weplay-media/index.vue"),
    meta: {
      title: "WEPLAY x 游戏动力",
      desc: "WEPLAY x 游戏动力",
      class: "__theme_white",
    },
  },
  {
    path: "/article-page/:id",
    name: "articepage",
    component: () => import("@/views/weplay-media/components/ArticePage.vue"),
    meta: {
      title: "WEPLAY x 游戏动力",
      desc: "WEPLAY x 游戏动力",
      class: "__theme_white",
    },
  },
  {
    path: "/supply-store",
    name: "supplystore",
    component: () => import("@/views/supply-store/index.vue"),
    meta: {
      title: "补给箱详情",
      desc: "补给箱详情",
      class: "__theme_white",
    },
  },
  {
    path: "/member-page",
    name: "memberpage",
    component: () => import("@/views/member-equity/index.vue"),
    meta: {
      title: "会员中心",
      desc: "会员中心",
      class: "__theme_white",
    },
  },
  {
    path: "/detail-says",
    name: "detailsays",
    component: () => import("@/views/destiny-draw/DetailSays.vue"),
    meta: {
      title: "详细说明",
      desc: "详细说明",
      class: "__theme_white",
    },
  },
  {
    path: "/free-book",
    name: "freebook",
    component: () => import("@/views/member-equity/components/FreeBook.vue"),
    meta: {
      title: "会员权益",
      desc: "会员权益",
      class: "__theme_white",
    },
  },
  {
    path: "/sure-recycle",
    name: "surerecycle",
    component: () => import("@/views/member-equity/components/SureRecyle.vue"),
    meta: {
      title: "会员权益",
      desc: "会员权益",
      class: "__theme_white",
    },
  },
  {
    path: "/mysterious-game",
    name: "mysteriousgame",
    component: () =>
      import("@/views/member-equity/components/MysteriousGame.vue"),
    meta: {
      title: "会员权益",
      desc: "会员权益",
      class: "__theme_white",
    },
  },
  {
    path: "/member-exclusive",
    name: "memberexclusive",
    component: () =>
      import("@/views/member-equity/components/MemberExclusive.vue"),
    meta: {
      title: "会员权益",
      desc: "会员权益",
      class: "__theme_white",
    },
  },
  {
    path: "/member-badge",
    name: "memberbadge",
    component: () => import("@/views/member-equity/components/MemberBadge.vue"),
    meta: {
      title: "会员权益",
      desc: "会员权益",
      class: "__theme_white",
    },
  },
  {
    path: "/consume-coin",
    name: "consumecoin",
    component: () => import("@/views/member-equity/components/ConsumeCoin.vue"),
    meta: {
      title: "会员权益",
      desc: "会员权益",
      class: "__theme_white",
    },
  },
  {
    path: "/consume-coin",
    name: "consumecoin",
    component: () => import("@/views/member-equity/components/ConsumeCoin.vue"),
    meta: {
      title: "会员权益",
      desc: "会员权益",
      class: "__theme_white",
    },
  },
  {
    path: "/preferential",
    name: "preferential",
    component: () => import("@/views/preferential/preferential.vue"),
    meta: {
      title: "",
      desc: "",
      class: "__theme_white",
    },
  },
  {
    path: "/timit-welfare-pop",
    name: "timitwelfarepop",
    component: () => import("@/views/timit-welfare/TimitWelfare.vue"),
    meta: {
      title: "",
      desc: "",
      class: "__theme_white",
    },
  },
  {
    path: "/timit-welfare-page",
    name: "timitwelfarepage",
    component: () => import("@/views/timit-welfare/TimitWelfarePage.vue"),
    meta: {
      title: "新人福利",
      desc: "新人福利",
      class: "__theme_white",
    },
  },
  {
    path: "/timit-welfare-pop-small",
    name: "timitwelfarepopsmall",
    component: () => import("@/views/timit-welfare/SmallPop.vue"),
    meta: {
      title: "",
      desc: "",
      class: "__theme_white",
    },
  },
  {
    path: "/wish-list/:aid",
    name: "wishlist",
    component: () => import("@/views/wish-list/index.vue"),
  },
  {
    path: "/equipment-test",
    name: "equipmentTest",
    component: () => import("@/views/equipmentTest.vue"),
    meta: {
      title: "愿望单活动",
      desc: "愿望单活动",
      class: "__theme_white",
    },
  },
  {
    path: "/daily-discount",
    name: "dailydiscount",
    component: () => import("@/views/daily-discount/index.vue"),
    meta: {
      title: "天天骨折",
      desc: "天天骨折",
    }
  },{
    path: "/test",
    name: "test",
    component: () => import("@/views/test.vue"),
    meta: {
      title: "test",
      desc: "test",
    }
  },{
    path: "/daily-discount",
    name: "dailydiscount",
    component: () => import("@/views/daily-discount/index.vue"),
    meta: {
      title: "天天骨折",
      desc: "天天骨折",
    },
  },{
    path: "/test",
    name: "test",
    component: () => import("@/views/test.vue"),
    meta: {
      title: "test",
      desc: "test",
      class: "__theme_white",
    },
  },
  {
    path: "/bottle-page",
    name: "bottlepage",
    component: () => import("@/views/bottle-page/index.vue"),
    meta: {
      title: "瓶盖主页",
      desc: "瓶盖主页",
      class: "__theme_white",
    },
  },
  {
    path: "/supreme-list",
    name: "supremelist",
    component: () => import("@/views/supreme-list/index.vue"),
    meta: {
      title: "至尊榜",
      desc: "至尊榜",
      class: "__theme_white",
    },
  },
  {
    path: "/blindbox-open",
    name: "blindboxopen",
    component: () => import("@/views/supreme-list/index.vue"),
    meta: {
      title: "至尊榜",
      desc: "至尊榜",
      class: "__theme_white",
    },
  },
  {
    path: "/recyle",
    name: "recyle",
    component: () => import("@/views/recyle/index.vue"),
    meta: {
      title: "回收",
      desc: "回收",
      class: "__theme_white",
    },
  },
  {
    path: "/recyle-detail",
    name: "recyleDetail",
    component: () => import("@/views/recyle/RecyleDetail.vue"),
    meta: {
      title: "回收",
      desc: "回收",
      class: "__theme_white",
    },
  },
];
