




































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import BlindBoxItem from "@/views/welfare/components/BlindBoxItem.vue";
import WelfareSwiper from "@/views/welfare/components/WelfareSwiper.vue";

import BlindBoxApi, {
  DiscountTags,
  IBlindBoxItem,
  IBlindBoxItemActive,
  SettingBlindBoxActivity,
} from "@/api/blind-box.api";
import { List, Dialog } from "vant";
import axios from "axios";
import { getNow } from "@/utils/common.util";
import { LOGOUT_NAME } from "@/constant";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

@Component({
  components: { BlindBoxItem, List, WelfareSwiper },
})
export default class WelfareBlindBox extends Vue {
  @Prop({ default: true }) private readonly isShowRun!: boolean;
  @Prop({ default: "" }) private readonly titleImg!: string;
  blindBox: IBlindBoxItem[] = [];
  page = 0;
  page_size = 10;
  loading = false;
  finished = false;

  mockData: IBlindBoxItemActive[] = [];
  globalState = getModule(GlobalStateStore);

  mounted() {
    const activity = (this.$route.query.activity as unknown as string) || "";
    if (activity) sessionStorage.setItem("activity", activity);
  }

  async handleActiveHiddenDataItem(result: SettingBlindBoxActivity) {
    const activity = sessionStorage.getItem("activity") || "";
    if (!result.library.includes(activity)) return;
    const start = result?.start_time || 0;
    const timeout = result.finish_time;
    const time = Math.round(new Date().getTime() / 1000);
    if (start > 0 && start > time) return;
    if (time >= timeout) return;
    const row = await BlindBoxApi.getBlindListOne(result.box_id).catch(
      () => undefined
    );
    if (!row) return;
    if (row.box_status === 1) return;
    if (row.price) row.pay_type = true;
    if (!row.box_inventory) {
      Dialog.alert({
        message: result.empty_tip,
        confirmButtonText: result.empty_confirm,
      });
    }
    this.mockData.push(row);
  }

  async handleActiveHiddenData() {
    const result = await BlindBoxApi.getBlindSettingActivity();
    if (!result || !result.length) return;
    this.hiddenUserOrder();
    return Promise.all(
      result.map((item) => this.handleActiveHiddenDataItem(item))
    );
  }

  @Emit()
  hiddenUserOrder() {
    return;
  }
  created(){
    this.loadData()
  }
  async loadData(): Promise<void> {
    this.page++;
    const { page, page_size, blindBox } = this;
    if (page === 1) await this.handleActiveHiddenData();
    const rows = await BlindBoxApi.getBlindList(page, page_size);
    this.loading = false;
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.blindBox =
      page > 1 ? [...blindBox, ...rows] : [...this.mockData, ...rows];
  }
}
