import moment from "moment";
import { TYPE_TEXT } from "@/constant";
import { cloneDeep, concat } from "lodash";
import InteractionLib from "@/utils/interaction.lib";
import { Dialog } from "vant";
import { RawLocation } from "vue-router/types/router";
import router from "@/router";
import { VanToast } from "vant/types/toast";

/**
 * @description 简易时间转秒
 * @param {String} time 1m 一分钟,1h 一小时,1d 一天
 * @return {Number} 返回秒数
 */
export function simpleStrToSeconds(time = "120m") {
  try {
    const match = time.match(/[^0-9]/)!;
    if (match.index === 0) return 0;
    const num = time.slice(0, match.index);
    const map = { m: 60, h: 3600, d: 86400 };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Number(num) * map[match[0]];
  } catch {
    return 0;
  }
}

export function countdown(time: number): ICountDown {
  const _time = moment(time * 1000).diff(moment());
  if (_time <= 0) return { day: "00", hour: "00", minute: "00", second: "00" };
  const t = _time / 1000;
  const day = Math.floor(t / (24 * 3600));
  const hour = Math.floor((t - 24 * 3600 * day) / 3600);
  const minute = Math.floor((t - 24 * 3600 * day - hour * 3600) / 60);
  const second = Math.floor(t - 24 * 3600 * day - hour * 3600 - minute * 60);
  return {
    day: day.toString().padStart(2, "0"),
    hour: hour.toString().padStart(2, "0"),
    minute: minute.toString().padStart(2, "0"),
    second: second.toString().padStart(2, "0"),
  };
}

export function monthday(time: number) {
  const _time = moment(time * 1000).diff(moment());
  console.log(_time)
  let day:any = new Date(time*1000).getDate()
  if(day < 10){
    day = '0'+day
  }
  let hour:any =new Date(time*1000).getHours()
  if(hour < 10){
    hour = '0'+hour
  }
  let min:any = new Date(time*1000).getMinutes()
  if(min < 10){
    min = '0'+min
  }
  let sec:any = new Date(time*1000).getSeconds()
  if(sec < 10){
    sec = '0'+sec
  }
  let year:any = new Date(time*1000).getFullYear()
  let month:any = new Date(time*1000).getMonth()+1
  if(month < 10){
    month = '0'+month
  }
  return {
    year,
    month,
    day,
    hour,
    min,
    sec
  }


}
export function observer(
  key: string,
  fun: EventListenerOrEventListenerObject
): void {
  document.addEventListener(key, fun);
}

export function unObserver(
  key: string,
  fun: EventListenerOrEventListenerObject
): void {
  document.removeEventListener(key, fun);
}

export function registerWinFun(key: string, fun: any): void {
  (window as any)[key] = fun;
}

export function createEvent(a: string, b: any) {
  const evt: any = new Event(a);
  evt.data = b;
  document.dispatchEvent(evt);
}

export function isMobile(userAgent: string): {
  ios: boolean;
  android: boolean;
  wechat: boolean;
} {
  const iPad = userAgent.indexOf("iPad") > -1;
  const iPhone = userAgent.indexOf("iPhone") > -1;
  const android =
    userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1;
  const wechat = userAgent.toLowerCase().indexOf("micromessenger") > -1;
  return {
    ios: iPad || iPhone,
    android,
    wechat,
  };
}

export function observable(key: string | Array<string>, data: any = ""): void {
  if (typeof key === "object") {
    for (const k of key) {
      observable(k, data);
    }
    return;
  }
  const evt = new Event(key);
  (evt as any).data = data ?? "";
  document.dispatchEvent(evt);
}

export function momentFormatNot(
  time?: number,
  days = false,
  separator = "-"
): string {
  if (time === 0) return "";
  return moment(!time ? undefined : time * 1000).format(
    days ? `MM${separator}DD HH:mm` : `HH:mm`
  );
}

export function getChance(cause?: number): string | number {
  if (!cause) return 0;
  const e = cause / 100;
  if (e > 0 && e < 0.1) return "0.1";
  return e;
}

export function getChanceCause(cause?: number, total?: number): number | string {
  if (!cause) return 0;
  const e = Math.floor((cause / (total || 0)) * 100000) / 1000;
  if (e > 0 && e < 0.1) return "0.1";
  return e;
}

export function getCharacter(
  character: number,
  thanZero = "无",
  close_peak_num = false,
  peak_start = 5
): string {
  if (character < 0) return thanZero;
  const text = TYPE_TEXT[character];
  if (character >= peak_start && close_peak_num) {
    return text.replace("一", "").replace("二", "").replace("三", "");
  }
  return text;
}

export function getCharacterColor(
  character: number,
  color = false
): Record<string, string> {
  const params: Record<string, string> = {};
  if (color) params.color = `var(--card-color-${character})`;
  return {
    ...params,
    background: `var(--card-color-${character})`,
  };
}

export function momentFormat(
  time?: number,
  days = false,
  separator = "-"
): string {
  if (time === 0) return "";
  return moment(!time ? undefined : time * 1000).format(
    days
      ? `YYYY${separator}MM${separator}DD`
      : `YYYY${separator}MM${separator}DD HH:mm:ss`
  );
}

export function getTomorrowMorning(): number {
  return Number(
    moment(
      momentFormat(Number(moment().add(1, "day").format("X")), true) +
        " 00:00:00"
    ).format("X")
  );
}

export function getCornerMarkColor(
  character: number,
  peak_start = 5
): { background: string } {
  return {
    background: `var(--card-color${
      character >= peak_start ? "-corner-mark" : ""
    }-${character})`,
  };
}

export function getNow(): number {
  return Math.ceil(Date.now() / 1000);
}

export async function delay(
  timeout = 1000,
  func?: () => void,
  callback?: (timeout: number) => void
): Promise<void> {
  return await new Promise<Promise<void> | void>((resolve) => {
    const timeout_key = setTimeout(() => {
      if (func) func();
      clearTimeout(timeout_key);
      resolve();
    }, timeout);
    callback && callback(timeout_key);
  });
}

export function scrollTop(selectors = "html"): void {
  document.scrollingElement?.scrollIntoView({
    behavior: "smooth",
  });
  document.querySelector(selectors)?.scrollIntoView({
    behavior: "smooth",
  });
  if (selectors === "html") {
    scroll({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }
}

export function handleDataLen<T = any[]>(value: T[], max_len = 20): T[] {
  const new_val = cloneDeep(value);
  if (new_val.length >= max_len) return new_val;
  const val = new Array(max_len - new_val.length).fill(0);
  let ii = 0;
  const arr = val.map((_, i) => {
    ii++;
    if (new_val[ii - 1])
      return {
        ...new_val[ii - 1],
        id: `te-${i}`,
      };
    ii = 0;
    return {
      ...new_val[ii],
      id: `te-${i}`,
    };
  }) as any[];
  return concat(value, ...arr);
}

export async function systemPay(
  channel: number,
  result: Record<"alipay" | "wechatPay" | any, any>,
) {
  const wechat = InteractionLib.mobile.wechat;
  if (wechat && channel == 3) {
    const { packageValue, ...payInfo } = result.wechatPay;
    return wechatPay({ ...payInfo, package: packageValue });
  }
  if ([1, 2].includes(channel)) {
    // 支付宝
    const payResult = await new Promise((resolve) => {
      if (channel === 1) InteractionLib.aliPay(result.alipay);
      else InteractionLib.wechatPay(result.wechatPay);
      // payResultWx
      let timer = 0;
      const payResult = (result: any) => {
        clearTimeout(timer);
        unObserver(channel === 1 ? "payResult" : "payResultWx", payResult);
        let payStatus: boolean;
        if (channel === 1)
          payStatus = ["9000", "8000", "6004"].includes(String(result.data));
        else payStatus = result.data == 0;
        resolve(payStatus);
      };
      timer = setTimeout(() => {
        resolve(false);
      }, 10000);
      observer(channel === 1 ? "payResult" : "payResultWx", payResult);
    });
    if (!payResult) {
      return await Dialog.confirm({
        message: "请您在新打开的支付页面进行支付，支付完成前请不要关闭窗口",
        confirmButtonText: "已完成支付",
        cancelButtonText: "取消",
      })
        .then(() => true)
        .catch(() => false);
    }
    return true;
  }
}

export async function wechatPay(payInfo: wx.WXPayOption) {
  return await new Promise<boolean>((resolve) =>
    wx.chooseWXPay({
      ...payInfo,
      success: function (e) {
        resolve(e.errMsg === "chooseWXPay:ok");
      },
      fail: function (e) {
        resolve(e.errMsg === "chooseWXPay:ok");
      },
      cancel: function (e) {
        resolve(e.errMsg === "chooseWXPay:ok");
      },
      complete: function (e) {
        resolve(e.errMsg === "chooseWXPay:ok");
      },
    })
  );
}

export function setBackgroundColor(color: string): void {
  document
    .querySelector("html")
    ?.setAttribute("style", `--background-color: ${color};`);
}

export function copy(e: string): void {
  const transfer = document.createElement("input");
  transfer.style.opacity = "0";
  document.body.appendChild(transfer);
  transfer.value = e; // 这里表示想要复制的内容
  // transfer.focus();
  transfer.select();
  if (document.execCommand("copy")) {
    document.execCommand("copy");
  }
  // transfer.blur();
  document.body.removeChild(transfer);
}

export interface ICountDown {
  hour: string;
  day: string;
  minute: string;
  second: string;
}
