import axios from "axios";
import { IUserInfo } from "@/types/global-state.type";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

export class MineApi {
  static async getCoupon(
    page = 1,
    page_size = 10,
    id?: number
  ): Promise<Coupon[]> {
    const params: Record<string, number> = {
      page,
      page_size,
    };
    if (id) params.id = id;
    return axios.get("/apiv2/mine/coupon", { params });
  }

  static async getMyCoupons(
    type: number,
    target: number,
    status = 0
  ): Promise<Coupon[]> {
    return await axios.get("/apiv2/mine/coupon", {
      params: {
        type,
        target,
        status,
      },
    });
  }

  static async loginByWechatCode(code: string): Promise<ILogin> {
    return axios.post(`/apiv2/v3/login/wechat/${code}`);
  }

  static async getUserInfo(): Promise<IUserInfo> {
    return axios.get("/apiv2/v3/login/userinfo");
  }

  static async getDeliverAddress(): Promise<IAddress> {
    return axios.get("/apiv2/v2/blindbox/deliver/address");
  }

  static async getOrderDetail(order_no: string): Promise<OrderResult> {
    return axios.get(`/order/mine/${order_no}`);
  }

  static async deleteOrder(order_no: string): Promise<boolean> {
    return axios
      .delete("/apiv2/mine/order", { data: { order_no } })
      .then(() => true)
      .catch(() => false);
  }

  static async getOrder(
    type = 0,
    page = 1,
    page_size = 10
  ): Promise<OrderResult[]> {
    return axios.get("order/mine", {
      params: {
        type,
        page,
        page_size,
      },
    });
  }
}

export interface OrderDetailItem {
  product_barcode: string;
  product_model_info: string;
  goods_id: number;
  buy_number: number;
  id: number;
  product_price: number;
  source: string;
  product_name: string;
  product_model_param: string;
  product_cover: string;
}

export interface OrderStatusItem {
  name: string;
  index: number;
  desc: string;
}

export interface OrderResult {
  order_no: string;
  deliver_time: number;
  create_time: number;
  address_id: number;
  order_price: number;
  express_company: string;
  product_number: number;
  pay_time: number;
  product_type: number;
  product_type_name: string;
  pay_no: string;
  pay_channel: number;
  id: number;
  product_total_price: number;
  detail: OrderDetailItem;
  express_number: string;
  logistics_price: number;
  status: OrderStatusItem;
  address?: {
    provinces: string;
    city: string;
    area: string;
  };
  deduction?: OrderResultDeduction;
}

export interface OrderResultDeduction {
  exchange_price: number;
  coupon_id: number;
  proportion: number;
  exchange_number: number;
  id: number;
  title: string;
}

export interface IAddress {
  address_id: number;
  consignee: string;
  phone: string;
  provinces: null | string[];
  city: null | string[];
  area: null | string[];
  detailed_address: string;
  zip_code: string;
}

export interface ILogin {
  token: string;
  openid: string;
  alias: string;
}

export interface Coupon {
  original_price: number;
  price_name: string;
  failure_time: number;
  code_conduct_ios: string;
  type: number;
  title: string;
  target: number;
  cover: string;
  coupon_id: number;
  user_id: number;
  price: number;
  failure: boolean;
  id: number;
  code_conduct: string;
  status: boolean;
}
