import { Toast } from "vant";
import { isMobile, observable } from "@/utils/common.util";

export default class InteractionLib {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private static WebViewJavascriptBridge: WebViewJavascriptBridge =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.WebViewJavascriptBridge;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private static messageHandlers: any = window.webkit?.messageHandlers;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private static flutter = window.flutter_inappwebview;

  static routers = {
    flutter: {
      baseURI: "/",
      routers: [
        "", // 绑定手机
        "", // 圈子页面
        "", // 发布帖子页面
        "", // 跳转折扣页面（游戏列表）
        "", // 跳转到消息中心
        "", // 跳转到消息中心
        "", // 跳转到首页
        "", // 跳转到发现游戏
        "", // 跳转到发布页面
        "", // 意见反馈
        "mine_discount_coupon", // 优惠券
        "mine_supply", // 我的补给
        "", // 填写发货地址
        "", // 发货记录
        "", // 确认订单
        "", // 合成自选
        "", // 挂售
        "", //挂售界面
        "home_tab", // 跳转tab,
        "game_detail", //跳转游戏商品详情
        "game_wish_web",
      ],
    },
    android: {
      baseURI: "com.vgn.gamepower.module.",
      routers: [
        "bind_phone.BindPhoneActivity", // 绑定手机
        "home.tab?tab=3", // 圈子页面
        "gamecircle.CirclePublishActivity", // 发布帖子页面
        "home.tab?tab=1", // 跳转折扣页面
        "mine.MessageCenterActivity", // 跳转到消息中心
        "mine.MessageCenterActivity", // 跳转到消息中心
        "home.tab?tab=0", // 跳转到首页
        "home.tab?tab=2", // 跳转到发现游戏
        "gamecircle.CirclePublishActivity", // 跳转到发布页面
        "about.FeedbackActitivy", // 意见反馈
        "mine.MineDiscountActivity", // 优惠券
        "mine.MineSupplyListActivity", // 我的补给
        "mine.ReceiveAddressActivity?id=", // 填写发货地址
        "mine.DispatchListActivity", // 发货记录
        "mine.ConfirmOrderActivity?id=", // 确认订单
        "mine.TargetCardActivity", // 合成自选
        "market.MarkethandSellActivity", // 挂售
        "market.HandSellActivity?id=", //挂售界面
        "home.tab", // 跳转tab
        "gameproduct.GameProductActivity", //跳转游戏商品详情
      ],
    },
    ios: {
      baseURI: "https://api.vgn.cn/router",
      routers: [
        "/mine/bindPhone", // 绑定手机
        "/gameMoment", // 圈子页面
        "/gameMoment/releaseMoment", // 发布帖子页面
        "/discountList", // 跳转折扣页面
        "/mine/messagecenter", // 跳转到消息中心
        "/mine/messagecenter", // 跳转到消息中心
        "/home", // 跳转到首页
        "/findGame", // 跳转到发现游戏
        "/gameMoment/releaseMoment", // 跳转到发布页面
        "/feedback", // 意见反馈
        "/myCoupons", // 优惠券
        "/mySupply", // 我的补给
        "/receiveAddressActivity?id=", // 填写发货地址
        "/deliveryList", // 发货记录
        "/confirmOrderActivity?id=", // 确认订单
        "/synthesisOfUpgrade", // 合成自选
        "", // 跳转TAB
        "gameDetail", //跳转到游戏商品详情
      ],
    },
  };
  static mobile = isMobile(navigator.userAgent);
  private static flutterInAppWebViewPlatformReadyInit = false;

  public static isFlutter() {
    return Boolean(this.flutter);
  }

  public static isApp() {
    if (this.mobile.wechat) return false;
    return (
      this.messageHandlers != undefined ||
      this.WebViewJavascriptBridge ||
      this.isFlutter()
    );
  }

  public static updateUserInfo() {
    try {
      return this.flutterCall("updateUserInfo");
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  public static async flutterInAppWebViewPlatformReady() {
    if (!this.flutter) return false;
    if (this.flutterInAppWebViewPlatformReadyInit) return true;
    return await new Promise<boolean>((resolve) => {
      setTimeout(() => {
        resolve(false);
      }, 3000);
      const flutterInAppWebViewPlatformReady = () => {
        this.getUserInfo();
        this.flutterInAppWebViewPlatformReadyInit = true;
        resolve(true);
        window.removeEventListener(
          "flutterInAppWebViewPlatformReady",
          flutterInAppWebViewPlatformReady.bind(this)
        );
      };
      window.addEventListener(
        "flutterInAppWebViewPlatformReady",
        flutterInAppWebViewPlatformReady.bind(this)
      );
    });
  }

  public static flutterCall(name: string, ...args: any[]) {
    if (!this.flutter) return undefined;
    return (async () => {
      try {
        console.log(name, JSON.stringify(args));
        return this.flutter.callHandler(name, ...args);
      } catch (e) {
        console.error("callHandler", name, args, e);
        throw new Error("not flutter");
      }
    })();
  }

  private static async callHandler(name: string, ...args: any[]) {
    if (!this.flutter) throw new Error("not flutter");
    try {
      return this.flutter.callHandler(name, ...args);
    } catch (e) {
      console.error("callHandler", name, args, e);
      throw new Error("not flutter");
    }
  }

  static getAppid(packageName = "com.vgn.gamepower") {
    if (this.mobile.wechat) return "wxebb0f826b7b56ce7";
    const appids = {
      "com.vgn.gamepower": "wx376baa793e909dae",
      "com.eshop.zzzb": "wxe7126fb063548a52",
      "com.vgn.steampro": "wxad83b375d9c8952c",
      "com.wechat.mall.ui": "wxebb0f826b7b56ce7",
      "com.vgn.flutter_steampro": "wxbef5ba4ac589d1de",
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return appids[packageName];
  }

  static setWebViewTitle(title: string) {
    try {
      if (this.flutterCall("setWebViewTitle", title)) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.setWebViewTitle(title);
      } else {
        this.messageHandlers.setWebViewTitle.postMessage(title);
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  static wrongTips() {
    return;
  }

  static functionInJs(params: string) {
    try {
      if (this.flutterCall("functionInJs", params)) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.functionInJs(params);
      } else {
        this.messageHandlers.functionInJs.postMessage(params);
      }
      return true;
    } catch (e) {
      this.wrongTips();
      return false;
    }
  }

  static getVersionCode() {
    try {
      const call = this.flutterCall("getVersionCode")?.then((ret) =>
        observable("setVersionCode", ret)
      );
      if (call) return;
      if (this.mobile.android) {
        const code = this.WebViewJavascriptBridge.getVersionCode();
        observable("setVersionCode", code);
      } else {
        this.messageHandlers.getVersionCode.postMessage("");
      }
      return true;
    } catch (e) {
      // Toast.fail("请在app中使用");
      return false;
    }
  }

  static getPackageName() {
    try {
      const call = this.flutterCall("getPackageName")?.then((ret) =>
        observable("setPackageName", ret)
      );
      if (call) return;
      if (this.mobile.android) {
        const name = this.WebViewJavascriptBridge.getPackageName();
        observable("setPackageName", name);
      } else {
        this.messageHandlers.getPackageName.postMessage("");
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  static isWishList() {
    try {
      this.flutterCall("isAddWishActivity", true);
    } catch (e) {
      console.error("goldVipStatusNotice", e);
    }
  }

  static hideWebMenu() {
    try {
      if (this.flutterCall("hideWebMenu")) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.hideWebMenu();
      } else {
        this.messageHandlers.hideWebMenu.postMessage("");
      }
      return true;
    } catch (e) {
      // Toast.fail("请在app中使用");
      return false;
    }
  }

  static getSystemInfo() {
    try {
      const call = this.flutterCall("getSystemInfo")?.then((ret) =>
        observable("setSystemInfo", ret / 2)
      );
      if (call) return;
      if (this.mobile.android) {
        const int = this.WebViewJavascriptBridge.getSystemInfo();
        observable("setSystemInfo", int / 2);
      } else {
        this.messageHandlers.getSystemInfo.postMessage("");
      }
      return true;
    } catch (e) {
      this.wrongTips();
      return false;
    }
  }

  static hideBoxPoint() {
    try {
      if (this.flutterCall("hideBoxPoint")) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.hideBoxPoint();
      } else {
        this.messageHandlers.hideBoxPoint.postMessage("");
      }
      return true;
    } catch (e) {
      this.wrongTips();
      return false;
    }
  }

  static updateGoldBlindBoxStatus() {
    try {
      this.flutterCall("updateGoldBlindBoxStatus");
    } catch (e) {
      console.error("updateGoldBlindBoxStatus", e);
    }
  }

  static async getHeader() {
    try {
      return this.flutterCall("getHeaders");
    } catch (e) {
      console.error("getHeaders", e);
    }
  }

  static noticeCloseSmallWindow() {
    try {
      this.flutterCall("noticeCloseSmallWindow");
    } catch (e) {
      console.error("noticeCloseSmallWindow", e);
    }
  }

  static handlerGet(product_official_id: number, active_id: number) {
    try {
      this.flutterCall(
        "addWishToSteam",
        String(product_official_id),
        active_id
      );
    } catch (e) {
      console.error("addWishToSteam", e);
    }
  }

  static closeSmall() {
    try {
      this.flutterCall("closePop");
    } catch (e) {
      console.error("closeSmall", e);
    }
  }

  static closePop() {
    try {
      this.flutterCall("closeSmall");
    } catch (e) {
      console.error("closePop", e);
    }
  }

  static goldVipStatusNotice() {
    try {
      this.flutterCall("goldVipStatusNotice");
    } catch (e) {
      console.error("goldVipStatusNotice", e);
    }
  }

  static getUserInfo() {
    try {
      const call = this.flutterCall("getUserInfo")?.then((ret) => {
        observable("setUserInfo", ret);
      });
      if (call) return;
      if (this.mobile.android) {
        observable("setUserInfo", this.WebViewJavascriptBridge.getUserInfo());
      } else {
        this.messageHandlers.getUserInfo.postMessage("");
      }
      return true;
    } catch (e) {
      this.wrongTips();
      return false;
    }
  }

  static shareWxCircle(data: IShareData) {
    try {
      if (this.flutterCall("shareWxCircle", JSON.stringify(data))) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.shareWxCircle(JSON.stringify(data));
      } else {
        this.messageHandlers.shareWxCircle.postMessage(JSON.stringify(data));
      }
    } catch (e) {
      this.wrongTips();
    }
  }

  static shareWeChat(data: IShareData) {
    try {
      if (this.flutterCall("shareWeChat", JSON.stringify(data))) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.shareWeChat(JSON.stringify(data));
      } else {
        this.messageHandlers.shareWeChat.postMessage(JSON.stringify(data));
      }
    } catch (e) {
      this.wrongTips();
    }
  }

  static login() {
    try {
      if (this.flutterCall("login")) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.login();
      } else {
        this.messageHandlers.login.postMessage("");
      }
      this.getUserInfo();
    } catch (e) {
      this.wrongTips();
    }
  }

  static openAndroidWeb(url: string) {
    if (this.flutterCall("openWeb", url)) return;
    this.WebViewJavascriptBridge.openWeb(url);
  }

  static wechatPay(params: any) {
    try {
      const data = JSON.stringify(params);
      if (this.flutterCall("wxPay", data)) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.wxPay(data);
      } else {
        this.messageHandlers.wxPay.postMessage(data);
      }
      return true;
    } catch (e) {
      this.wrongTips();
      return false;
    }
  }

  static aliPay(str: string) {
    try {
      if (this.flutterCall("aliPay", str)) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.aliPay(str);
      } else {
        this.messageHandlers.aliPay.postMessage(str);
      }
      return true;
    } catch (e) {
      this.wrongTips();
      return false;
    }
  }

  static setSupplyFreeStatus(id: number) {
    try {
      if (this.flutterCall("setSupplyFreeStatus", id)) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.setSupplyFreeStatus(id);
      } else {
        this.messageHandlers.setSupplyFreeStatus.postMessage(id);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  static openGameWishWebView(url: string) {
    const { ios } = this.routers;
    const router = this.mobile.android
      ? ""
      : ios.baseURI + `/browser/innerbrowser?web_url=${url}`;
    try {
      if (this.flutterCall("game_wish_web", url)) return;
      if (this.mobile.android) {
        this.openAndroidWeb(url);
      } else {
        this.messageHandlers.functionInJs.postMessage(
          JSON.stringify({
            code_conduct_ios: router,
          })
        );
      }
    } catch (e) {
      this.wrongTips();
    }
  }

  static openWebView(url: string) {
    const { ios } = this.routers;
    const router = this.mobile.android
      ? ""
      : ios.baseURI +
        `/browser/innerbrowser?web_url=${encodeURIComponent(url)}`;
    try {
      if (this.flutterCall("openWeb", url)) return;
      if (this.mobile.android) {
        this.openAndroidWeb(url);
      } else {
        this.messageHandlers.functionInJs.postMessage(
          JSON.stringify({
            code_conduct_ios: router,
          })
        );
      }
    } catch (e) {
      this.wrongTips();
    }
  }

  static _height = 0;

  static resizeOffsetHeight() {
    setInterval(() => {
      const _height =
        document.documentElement.scrollHeight ||
        document.documentElement.offsetHeight;
      if (_height !== this._height) {
        this._height = _height;
        this.flutterCall("resizeOffsetHeight", _height);
      }
    }, 100);
  }

  static screenshotListen() {
    this.flutterCall("screenshotListen");
  }

  static copyListen() {
    this.flutterCall("copyListen");
  }

  static router(type: number, params?: any) {
    const { android, ios, flutter } = this.routers;
    let router = this.mobile.android
      ? android.baseURI + android.routers[type]
      : ios.baseURI + ios.routers[type];
    const flutter_router = flutter.baseURI + flutter.routers[type];
    const urlParams = (params?: any) => {
      if (!params) return "";
      if (typeof params !== "object") return "";
      return (
        "?" +
        Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&")
      );
    };
    if (params) router += urlParams(params);
    const data = this.mobile.android ? btoa(router) : router;
    try {
      if (this.flutterCall("router_flutter", flutter_router, params)) return;
      if (this.mobile.android) {
        this.WebViewJavascriptBridge.functionInJs(
          JSON.stringify({
            code_conduct: data,
          })
        );
      } else {
        this.messageHandlers.functionInJs.postMessage(
          JSON.stringify({
            code_conduct_ios: data,
          })
        );
      }
    } catch {
      this.wrongTips();
    }
  }
}

export interface IShareData {
  title: string;
  image: string;
  url: string;
}

export interface WebViewJavascriptBridge {
  hideBoxPoint: () => void;
  wxPay: (str: string) => void;
  aliPay: (str: string) => void;
  getUserInfo: () => string;
  getSystemInfo: () => number;
  openWeb: (url: string) => void;
  shareWxCircle: (params: string) => boolean;
  shareWeChat: (params: string) => boolean;
  login: () => void;
  functionInJs: (params?: string) => void;

  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: Function;
}
