
































































import { Component, Prop, Vue } from "vue-property-decorator";
import { IBlindBoxItem } from "@/api/blind-box.api";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({})
export default class BlindBoxItem extends Vue {
  @Prop() private readonly item!: IBlindBoxItem;

  handleOpenBlindBox(): void {
    const { id } = this.item;
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/blind-box/${id}`);
    }
    location.href = `${location.origin}/blind-box/${id}`;
  }

  get ribbonStyle(): Record<string, string> {
    const [right, left] = this.item.ribbon || ["", ""];
    return {
      "--right-img": `url(${right})`,
      "--left-img": `url(${left})`,
    };
  }

  get covers(): Array<string> {
    const { pictures = [] } = this.item;
    const len = pictures.length - 5;
    const n = len > 0 ? Array(len).fill("") : [];
    console.log(this.item);

    return [...pictures, ...n];
  }

  get rootStyle(): Record<string, string> {
    const [start, end] = this.item.background || ["", ""];
    return {
      background: `linear-gradient(180deg, #${start} 0%, #${end} 100%)`,
    };
  }
  created(){
    console.log(this.item);
  }
  get footerStyle(): Record<string, string> {
    const { footer } = this.item;
    return {
      backgroundImage: `url("${footer}")`,
    };
  }
}
