






















import { MallHomeHotSubject } from "@/api/cap-store.api";
import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {},
})
export default class HotSales extends Vue {
  @Prop() items!: MallHomeHotSubject;
  handleOpenDetail(id: number): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/special-sale/${id}`);
    }
    this.$router.push({
      name: "specialsale",
      params: { id: `${id}` },
      query: { time: String(getNow()),id: `${id}`  },
    });
  }
}
