import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import { initialUnencryptedStorage } from "@/store/globals";
import { IConfig, IUserInfo } from "@/types/global-state.type";
import { MineApi } from "@/api/mine.api";
import InteractionLib from "@/utils/interaction.lib";
import { OPENID_NAME, TOKEN_NAME, PACKAGE_NAME } from "@/constant";
import axios from "axios";
import { omit, pick } from "lodash";

@Module({
  namespaced: true,
  name: "GlobalStateStore",
  store,
  dynamic: true,
  preserveState: Boolean(initialUnencryptedStorage["GlobalStateStore"]), // preserve state when available from local storage, else don't preserve
})
export default class GlobalStateStore extends VuexModule {
  userToken: string | null = null;
  openId: string | null = null;
  userInfo: IUserInfo | null = null;
  loginTip = false;
  statusBarHeight = 0;
  versionCode = "1.0.0";
  packageName = "";
  sce = 0;
  config: IConfig | null = null;
  audit_version = false;
  transparence = 1;
  headerData = {};

  @Mutation
  setPackageName(data: string) {
    this.packageName = data;
    // eslint-disable-next-line no-prototype-builtins
    if (!PACKAGE_NAME.hasOwnProperty(data)) {
      this.packageName = Object.keys(PACKAGE_NAME)[0];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.title = PACKAGE_NAME[data] || "游戏动力";
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    InteractionLib.setWebViewTitle(PACKAGE_NAME[data] || "游戏动力");
  }

  @Mutation
  setSystemInfo(data: number) {
    this.statusBarHeight = Math.ceil(data) + 5;
  }

  @Mutation
  setTransparence(data: number) {
    this.transparence = data;
  }

  @Mutation
  setVersionCode(data: string) {
    this.versionCode = data;
  }

  @Mutation
  setLoginTip(status: boolean) {
    this.loginTip = status;
  }

  @Mutation
  setHeaderData(status: any) {
    this.headerData = pick(status, [
      "channel",
      "device_id",
      "device_name",
      "device_type",
      "screen_resolution",
      "system_version",
      "ua",
      "version",
      "version_code",
    ]);
  }

  @Mutation
  setUserInfo(info: IUserInfo | null): void {
    if (info && info.user_id == 951) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new VConsole();
      } catch (e) {
        console.error(e);
      }
    }
    this.userInfo = info;
  }

  @Mutation
  setSce(num: any) {
    this.sce = num;
  }

  @Mutation
  setConfig(config: IConfig) {
    // this.audit_version = config.audit_version && InteractionLib.mobile.ios;
    this.config = config;
  }

  @Action({ commit: "setUserInfo", rawError: true })
  async getUserInfo(): Promise<IUserInfo> {
    return await MineApi.getUserInfo();
  }

  @Action({ commit: "setConfig", rawError: true })
  async getConfig(): Promise<IConfig> {
    return axios.get(`/apiv2/config`);
  }

  @Mutation
  setUserToken(token: string | null): void {
    this.userToken = token;
    if (token) this.loginTip = false;
    localStorage.setItem(TOKEN_NAME, token || "");
  }

  @Mutation
  setOpenId(openid: string | null): void {
    this.openId = openid;
    localStorage.setItem(OPENID_NAME, openid || "");
  }
}
